// src/store.ts
import { createStore } from 'vuex';

export default createStore({
  state: {
    selectedMenus: [] as Menu[],
    totalPrice: 0,
  },
  mutations: {
    ADD_MENU(state, menu: Menu) {
      state.selectedMenus.push(menu);
      state.totalPrice += menu.menu_price;
    },
    REMOVE_MENU(state, menuId: number) {
      const index = state.selectedMenus.findIndex(menu => menu.menu_id === menuId);
      if (index !== -1) {
        state.totalPrice -= state.selectedMenus[index].menu_price;
        state.selectedMenus.splice(index, 1);
      }
    },
  },
  actions: {
    addMenu({ commit }, menu: Menu) {
      commit('ADD_MENU', menu);
    },
    removeMenu({ commit }, menuId: number) {
      commit('REMOVE_MENU', menuId);
    },
  },
  getters: {
    totalPrice: (state) => state.totalPrice,
    selectedMenuCount: (state) => state.selectedMenus.length,
  },
});

interface Menu {
  menu_id: number;
  menu_name: string;
  menu_price: number;
}
