<template>
  <ion-page>
    <ion-tabs  >
      <ion-router-outlet></ion-router-outlet>

      <ion-tab-bar slot="bottom" v-if="showTabs" class="tabs" style="" >
        <ion-tab-button tab="tab1" href="/tabs/tab1"  @click="setActiveTab('tab1')">
          <ion-icon  v-if="activeTab === 'tab1'" aria-hidden="true"  src="/icons/menu.svg" style="color: white; max-width: 24px;" ></ion-icon>          
          <ion-icon v-else aria-hidden="true" src="/icons/menu-gray.svg"  style="max-width: 24px;"></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/tabs/tab2" @click="setActiveTab('tab2')">    
          <ion-icon v-if="activeTab === 'tab2'" aria-hidden="true"  src="/icons/newspaper.svg" style="max-height: 22px; max-width: 21px;" ></ion-icon>
          <ion-icon v-else aria-hidden="true" src="/icons/newspaper-gray.svg" style="max-height: 22px; max-width: 21px;" ></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/tabs/tab4" @click="setActiveTab('tab4')">
          <ion-icon v-if="activeTab === 'tab4'" aria-hidden="true" src="/icons/card.svg" style="width: 35%; max-width: 23px;" > </ion-icon>  
          <ion-icon v-else aria-hidden="true" src="/icons/card-gray.svg"  style="max-width: 23px;"> </ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/tabs/tab3" @click="setActiveTab('tab3')" >
          <ion-icon v-if="activeTab === 'tab3'" aria-hidden="true"  src="/icons/user.svg" style="max-width: 21px;" />
          <ion-icon v-else aria-hidden="true"  src="/icons/user-gray.svg" style="max-width: 21px;"  ></ion-icon>
        </ion-tab-button>

        

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import { ref, watch, computed  } from 'vue'
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { personOutline, person, wallet, walletOutline, documents, documentsOutline, newspaperOutline, newspaper, 
  analyticsOutline, card, cardOutline } from 'ionicons/icons';
import { IonFabButton } from '@ionic/vue';
import { menuController, IonButton } from '@ionic/vue';
import { showModal } from '@/globalState';
import { useRoute } from 'vue-router';

const activeTab = ref('tab1');
const route = useRoute();
const hiddenTabsRoutes = ['/tabs/login', '/tabs/register', '/tabs/forgot-password', '/tabs/tab5','/tabs/cart'];

// Реактивное свойство для контроля видимости табов
// const showTabs = computed(() => {
//   const isHiddenRoute = hiddenTabsRoutes.some(path => route.path.startsWith(path));
//   return !isHiddenRoute;
// });

// watch(route, (currentRoute) => {
//   const tabMap: Record<string, string> = {
//     '/tabs/tab1': 'tab1',
//     '/tabs/tab2': 'tab2',
//     '/tabs/tab3': 'tab3',
//     '/tabs/tab4': 'tab4',
//   };
//     const matchedTab = tabMap[currentRoute.path] || 'tab1';
//     activeTab.value = matchedTab;
//   }
// );
// const setActiveTab = (tabName: string) => {
//   activeTab.value = tabName;};

</script>



