import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
// import { Task } from '../models/models.ts';
// import Login  from '../views/Login.vue'
// import HorseDetailPage from '@/views/HorseDetailPage.vue';
// import RiderDetaliPage from '@/views/RiderDetaliPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/tab1',
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/Register.vue')
  },

  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1',
        meta: { requiresAuth: true }
      },
      {
        path: 'tab1',
        name: 'tab1',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2Page.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3Page.vue')
      },
      {
        path: 'tab4',
        name: 'tab4',
        component: () => import('@/views/Tab4Page.vue')
      }
      ,
      {
        path: 'tab5',
        name: 'tab5',
        component: () => import('@/views/Tab5Page.vue')
      },
      {
        path: 'tab6',
        name: 'tab6',
        component: () => import('@/views/Tab6Page.vue')
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/Register.vue')
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/Forgot-password.vue')
      }
      ,
      {
        path: 'FAQ',
        name: 'FAQ',
        component: () => import('@/views/FAQ.vue')
      }
      ,
      {
        path: 'shop-cart',
        name: 'shop-cart',
        component: () => import('@/views/shop-cart.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Проверяем наличие токена
    if (!localStorage.getItem('accessToken')) {
      // Перенаправляем на страницу логина
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
